import {BaseApi} from "./BaseApi";
import IProject from "./models/IProject";
import IProposal from "./models/IProposal";
import {getIdFromLink} from "../shared/helper";

export class ProjectApi extends BaseApi {

  public getAllProjects(filters: {name: string, value: any, type: string }[],
                        page= 1, pageSize=10, sortField=null,
                        sortOrder=null, onlyStatues: string[]=[], yourGchouses: string[]|null=null): Promise<IProject[]> {
    let url = '/dccockpit/projects?filters='+JSON.stringify(filters)+"&page="+page+"&pageSize="+
        pageSize;
    if (yourGchouses && yourGchouses.length > 0) {
      url = url+"&gchouses="+yourGchouses.join(",");
    }
    if (sortOrder) {
      url = url+"&sortOrder="+sortOrder;
    }
    if (sortField) {
      url = url + "&sortField="+sortField;
    }
    if (onlyStatues.length>0) {
        url = url + "&onlyStatuses="+onlyStatues.join(",");
    }

    return this.requestGet(url);
  }


  public getAllPortingProjects(filters: {name: string, value: any, type: string }[],
                        page= 1, pageSize=10, sortField=null,
                        sortOrder=null, onlyStatues: string[]=[], yourGchouses: string[]|null=null): Promise<IProject[]> {
    if (!filters) {
      filters = [];
    }
    filters.push({type: "text", name: "ProjectTemplateId", value: "2042"});
    let url = '/dccockpit/projects?filters='+JSON.stringify(filters)+"&page="+page+"&pageSize="+
        pageSize;
    if (yourGchouses && yourGchouses.length > 0) {
      url = url+"&gchouses="+yourGchouses.join(",");
    }
    if (sortOrder) {
      url = url+"&sortOrder="+sortOrder;
    }
    if (sortField) {
      url = url + "&sortField="+sortField;
    }
    if (onlyStatues.length>0) {
      url = url + "&onlyStatuses="+onlyStatues.join(",");
    }

    return this.requestGet(url);
  }

  public getAllProposals(stat: any): Promise<IProposal[]> {
    return this.requestGet('/dccockpit/projects/proposals?finished='+stat.finished+"&declined="+stat.declined);
  }

  public getClosedProjects(): Promise<IProject[]> {
    return this.requestGet('/dccockpit/projects?state=closed');
  }

  public getProposalDetails(projectId: string): Promise<IProposal> {
    return this.requestGet('/dccockpit/projects/proposals/'+projectId);
  }
  public getProjectDetails(projectId: string): Promise<IProject> {
    return this.requestGet('/dccockpit/projects/'+projectId);
  }

  public createProject(order: IProject): Promise<any> {
    return this.requestPost('/dccockpit/projects/', order);
  }
  public createProposal(order: IProject): Promise<any> {
    return this.requestPost('/dccockpit/projects/proposal', order);
  }
  public requestProjectApproval(orderId: string): Promise<any> {
    return this.requestGet('/dccockpit/projects/requestApproval/'+ orderId);
  }


  public getCategories(): Promise<any> {
    return this.requestGet('/dccockpit/articles/categories');
  }
  public getArticles(): Promise<any> {
    return this.requestGet('/dccockpit/articles');
  }

  public getProjectConfig(data: any): Promise<any> {
    return this.requestPost('/dccockpit/projects/configuration/', data);
  }


  getAllStatus(projectTemplateId?: string) {
    if (projectTemplateId) {
      return this.requestGet('/dccockpit/projects/status/' + projectTemplateId);

    }
    else {
        return this.requestGet('/dccockpit/projects/status');
    }

  }

  getStatusForPosition(productConfig: any, configname?:string ) {
    const kind = productConfig.kind ? productConfig.kind : "website";
    const id = productConfig._id ? productConfig._id : getIdFromLink(productConfig.link);
    return this.requestGet('/dccockpit/projects/statusForPosition/'+kind+"/"+id+(configname ? "/"+configname : ""));
  }

  getProjectDetailsForPublic(projectId: string) {
    return this.requestGet('/dccockpit/projectsPublic/'+projectId);
  }

  public discardProject(project: any): Promise<any> {
    return this.requestPost('/dccockpit/projectsPublic/discard', project);
  }

  public approveProject(project: any): Promise<any> {
    return this.requestPost('/dccockpit/projectsPublic/approve', project);
  }

  public getSubProjects(projectIds: string): Promise<any> {
    return this.requestGet('/dccockpit/projects/subprojects/'+projectIds);
  }

  public deleteProject(projectId: string): Promise<any> {
    return this.requestDelete('/dccockpit/projects/'+projectId);
  }

  public trackProject(projectId: string, track: boolean): Promise<any> {
    return this.requestPost('/dccockpit/projects/'+projectId+"/tracking", {track});
  }

  preApproveProject(project: any, additionalData: any = {}) {
    return this.requestPost('/dccockpit/projectsPublic/preapprove', {
      ...project,
        ...additionalData
    });
  }

  getRecurringRevenues(recurrenceId: string) {
    return this.requestGet('/dccockpit/projects/recurrence/'+recurrenceId);
  }

  getExistingElement(customerId: any, kind: string) {
    return this.requestGet('/dccockpit/projects/findExistingModels/'+customerId+"/"+kind);
  }

  sendProject(projectId: any, additionalData: any = {}) {
    return this.requestPost('/dccockpit/projects/sendProposal/'+projectId, {...additionalData});
  }

  setOrderApprovementField(project: any, company: any, contractCheckedElement: any , checked: boolean) {
    return this.requestPost('/dccockpit/projects/setOrderApprovementField', {
      project: project,
      company: company,
      item: contractCheckedElement,
      checked: checked
    });
  }

  getReportingApiUrl() {
    return this.requestGet('/metabase/3');
  }

  getOrderApprovementField(project: any, company: any, contract: any) {
    return this.requestPost('/dccockpit/projects/getOrderApprovementField', {
      projectId: project.Id,
      companyId: company.Id,
      item: contract
    })
        .then((data: any) => {
          if (data.status === "OK") {
            return data.data;
          }
            else {
                return null;
          }
        })
  }
}

export default new ProjectApi();
